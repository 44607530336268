/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import gsap from "gsap";
import { useEffect } from "react";
import { Draggable } from "gsap/Draggable";
import VideoRotary3D from "./3D.component";
import VideoRotary2D from "./2D.component";

import "./VideoRotary.styles.scss";

gsap.registerPlugin(Draggable);

const VideoRotary = ({
  video,
  socketID,
  updatePosition,
  updatePositionVertical,
  updateFinalPosition,
  updateFinalPositionVertical,
  initialAngleX = 0,
  initialAngleY = 0,
  updateIs3D,
}) => {
  /*//////////////////////*/
  /* Events
  /*/ /////////////////////*/
  const updateAngleX = (angle) => {
    updatePosition(angle);
  };

  const updateAngleY = (angle) => {
    updatePositionVertical(angle);
  };

  const updateFinalX = (angle) => {
    updateFinalPosition(angle);
  };

  const updateFinalY = (angle) => {
    updateFinalPositionVertical(angle);
  };

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/

  useEffect(() => {
    if (video) updateIs3D(video?.accept3D);
    return () => {
      if (video) updateIs3D(false);
    };
    // eslint-disable-next-line
  }, [video]);

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/

  return (
    <div className="videoRotary mt-6">
      {video?.accept3D ? (
        <VideoRotary3D
          video={video}
          socketID={socketID}
          initialAngleX={initialAngleX}
          initialAngleY={initialAngleY}
          updateAngleX={updateAngleX}
          updateAngleY={updateAngleY}
          updateFinalX={updateFinalX}
          updateFinalY={updateFinalY}
        />
      ) : (
        <VideoRotary2D
          video={video}
          initialAngleX={initialAngleX}
          updateAngleX={updateAngleX}
          updateFinalX={updateFinalX}
        />
      )}
    </div>
  );
};

export default VideoRotary;
