/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { Link } from "react-router-dom";
import Title from "../Title/Title.component";
import { ArrowLeft } from "../../icons/ArrowLeft.icon";
import "../../icons/ArrowLeft.icon";

import "./BackButton.styles.scss";
import { useState } from "react";

const BackButton = ({
  text,
  textButton = "Retour",
  link = "",
  action = false,
  back = true,
}) => {
  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/

  const backText = useState(textButton === "Retour" ? window.location.href.includes("locale=en") ? "Back" : "Retour" : textButton)

  return (
    <div
      className={`back-button | sticky top-0 flex items-center bg-white z-50 ${
        back ? "justify-start" : "justify-center"
      }`}
    >
      {back && (
        <div className="w-auto md:w-4/12 absolute left-0 md:relative">
          {action ? (
            <button
              onClick={action}
              className="sticky top-0 flex justify-start items-center z-50 py-6"
            >
              <ArrowLeft className="icon" />
              {document?.body?.clientHeight > (document?.getElementsByClassName("main__container")[0]?.clientHeight + 22) &&
                <p className="pl-2">{backText}</p>
              }
            </button>
          ) : (
            <Link
              to={link}
              className="sticky top-0 flex justify-start items-center z-50 py-6"
            >
              <ArrowLeft className="icon" />
              {document?.body?.clientHeight > (document?.getElementsByClassName("main__container")[0]?.clientHeight + 22) &&
                <p className="pl-2">{backText}</p>
              }
            </Link>
          )}
        </div>
      )}

      

      {text && (
        <div className="w-full md:w-4/12 text-center relative z-10 px-[70px] md:px-0 py-6 flex justify-center gap-4 item-center">
          {document?.body?.clientHeight < (document?.getElementsByClassName("main__container")[0]?.clientHeight + 22) &&
            <button 
              className="rounded-md flex item-center justify-center relative" 
              onClick = {e => {document?.getElementsByClassName("video-player__chapters")[0] ? document.getElementsByClassName("video-player__chapters")[0].scrollTop = document.getElementsByClassName("video-player__chapters")[0].scrollTop - 200 :  document.getElementsByTagName("main")[0].scrollTop = document.getElementsByTagName("main")[0].scrollTop - 200}} 
              style = {{width : "40px", height : "40px", minWidth : "40px", translate : "0px -4px"}}>
                <div className=" absolute h-full w-full bg-light-primary rounded-md" ></div>
                <p className="flex text-primary" style = {{rotate : "90deg"}}><ArrowLeft className="icon" /></p>
              </button>
          }
          <Title text={text} />
          {document?.body?.clientHeight < (document?.getElementsByClassName("main__container")[0]?.clientHeight + 22) &&
            <button 
              className="rounded-md flex item-center justify-center relative" 
              onClick = {e => {document?.getElementsByClassName("video-player__chapters")[0] ? document.getElementsByClassName("video-player__chapters")[0].scrollTop = document.getElementsByClassName("video-player__chapters")[0].scrollTop + 200 :  document.getElementsByTagName("main")[0].scrollTop = document.getElementsByTagName("main")[0].scrollTop + 200}} 
              style = {{width : "40px", height : "40px", minWidth : "40px", translate : "0px -4px"}}>
                <div className=" absolute h-full w-full bg-light-primary rounded-md" ></div>
                <p className="flex text-primary" style = {{rotate : "-90deg"}}><ArrowLeft className="icon" /></p>
              </button>
          }
        </div>
      )}

      
    </div>
  );
};

export default BackButton;
