/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useEffect, useMemo, useRef, useState } from "react";
import {
  setSocketsContentGoToPosition,
  setSocketsContentGoToFinalPosition,
  setSocketsContentGoToPositionVertical,
  setSocketsContentIs3D,
} from "../../../utils/websockets.utils";
import { getDifference } from "../../../utils/helpers.utils";
import VideoRotary from "../Rotary/VideoRotary.component";
import VideoSlider from "../Slider/VideoSlider.component";

const VideoOther = ({
  video,
  socketID,
  socket,
  isError,
  isLoaded,
  initialAngleX = 0,
  initialAngleY = 0,
  updateValueX = () => {},
  updateValueY = () => {},
}) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  let [currentPosition, _setCurrentPosition] = useState(0);
  let [finalPosition, _setFinalPosition] = useState(0);
  const positionRef = useRef(currentPosition);
  const finalPositionRef = useRef(finalPosition);
  const socketsReady = useRef(false);
  const VideoType = useMemo(
    () => (video.type === "Rotary" ? VideoRotary : VideoSlider),
    [video]
  );

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    if (socket && !socketsReady.current) {
      socket.on("videoRemote", imagesRemote);
      socketsReady.current = true;
    }
    return () => {
      if (socket) socket.off("videoRemote", imagesRemote);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/

  /* événement videoRemote */
  const imagesRemote = ({ exists }) => {
    isLoaded();
    if (!exists) {
      isError(true);
    } else {
      setSocketsContentGoToFinalPosition(initialAngleX, video.fov ? video.fov : 80, socketID);
    }
  };

  /* state update helper */
  const setCurrentPosition = (data) => {
    positionRef.current = data;
    _setCurrentPosition(data);
  };

  const setFinalPosition = (data) => {
    finalPositionRef.current = data;
    _setFinalPosition(data);
  };


  /*//////////////////////*/
  /* Events
  /*/ /////////////////////*/

  /* event updater */
  const updatePosition = (newPosition) => {
    if (getDifference(newPosition, positionRef.current)) {
      setCurrentPosition(newPosition);
      updateValueX(newPosition);
      setSocketsContentGoToPosition(newPosition, socketID);
    }
  };

  const updatePositionVertical = (positionVertical) => {
    updateValueY(positionVertical);
    setSocketsContentGoToPositionVertical(positionVertical, socketID);
  };

  const updateFinalPosition = (finalPosition) => {
    if (getDifference(finalPosition, finalPositionRef.current)) {
      setFinalPosition(finalPosition);
      setSocketsContentGoToFinalPosition(finalPosition, video.fov ? video.fov : 80, socketID);
    }
  };

  const updateFinalPositionVertical = (finalPosition) => {
    /* final position vertical*/
  };

  const updateIs3D = (is3D) => {
    setSocketsContentIs3D(is3D, socketID);
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <div className="w-full max-w-sm mb-12">
      <VideoType
        video={video}
        socketID={socketID}
        updateIs3D={updateIs3D}
        initialAngleX={initialAngleX}
        initialAngleY={initialAngleY}
        updatePosition={updatePosition}
        updatePositionVertical={updatePositionVertical}
        updateFinalPosition={updateFinalPosition}
        updateFinalPositionVertical={updateFinalPositionVertical}
      />
    </div>
  );
};

export default VideoOther;
