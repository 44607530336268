/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlayPause } from "../../../icons/PlayPause.icon";
import { setCurrentDuration } from "../../../store/projects/projects.reducer";
import { getProjectsReducer } from "../../../store/projects/projects.selector";
import {
  setSocketsVideoPlay,
  setSocketsVideoTimer,
} from "../../../utils/websockets.utils";
import VideoChapter from "../Chapter/VideoChapter.component";
import { Reload } from "../../../icons/Reload.icon";

import "./VideoPlayer.styles.scss";

const VideoPlayer = ({
  video,
  socketID,
  socket,
  isError,
  isLoaded,
  reloadContent,
}) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const dispatch = useDispatch();
  const { currentDuration } = useSelector(getProjectsReducer);
  const [isPlaying, _setIsPlaying] = useState(false);
  const [activeChapter, setActiveChapter] = useState(null);
  const [chapters, setChapters] = useState(null);
  const isPlayingRef = useRef(isPlaying);
  const socketsReady = useRef(false);
  const chaptersRef = useRef(null);
  const [chaptersHeight, setChaptersHeight] = useState(0);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!video?.disableAuto) onPlay();
    }, 500);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* sockets events */
  useEffect(() => {
    if (socket && !socketsReady.current) {
      socket.on("videoRemote", videoRemote);
      socketsReady.current = true;
    }
    return () => {
      if (socket) {
        socket.off("videoRemote", videoRemote);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    if (typeof window != "undefined")
      window.addEventListener("resize", updateChaptersHeight);
    return () => {
      if (typeof window != "undefined")
        window.removeEventListener("resize", updateChaptersHeight);
    };
  }, []);

  useEffect(() => {
    if (video?.chaptersFileURL) {
      const getChapters = async () => {
        try {
          const response = await fetch(
            process.env.REACT_APP_STRAPI + video.chaptersFileURL
          );
          const contentBuffer = await response.arrayBuffer();
          const utf8Decoder = new TextDecoder("utf-8");
          const decodedString = utf8Decoder.decode(contentBuffer);
          const data = await new DOMParser().parseFromString(
            decodedString,
            "text/xml"
          );
          const chapters = [...data.querySelectorAll("marker")].map(
            (chapter, id) =>
              chapter.querySelector("time").getAttribute("value") && {
                id,
                name: chapter.querySelector("comment").getAttribute("value"),
                seconds: +chapter.querySelector("time").getAttribute("value"),
                end:
                  +chapter.querySelector("duration").getAttribute("value") +
                  +chapter.querySelector("time").getAttribute("value"),
              }
          );
          setChapters(chapters);
        } catch (e) {
          console.log({ e });
        }
      };
      getChapters();
    } else if (video?.chapters) {
      setChapters(video.chapters);
    }
  }, [video]);

  useEffect(() => {
      console.log(chapters)
  }, [chapters])

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/

  const updateChaptersHeight = () => {
    if (chaptersRef.current && typeof window != "undefined") {
      setChaptersHeight(
        window.innerHeight - chaptersRef.current.getBoundingClientRect().y - 50
      );
    }
  };

  const setIsPlaying = (data) => {
    isPlayingRef.current = data;
    _setIsPlaying(data);
  };

  const videoRemote = ({ exists, total }) => {
    isLoaded();
    if (!exists) isError(true);
    dispatch(setCurrentDuration(total ? total : 0));
  };

  /*//////////////////////*/
  /* Events
  /*/ /////////////////////*/

  const onPlay = () => {
    setIsPlaying(!isPlaying);
    setSocketsVideoPlay(isPlayingRef.current, socketID);
    setActiveChapter(false);
  };

  const onChapterUpdate = (time, timer) => {
    /* pause video avant update*/
    setIsPlaying(false);
    setSocketsVideoPlay(false, socketID);
    /* update chapitre */
    setSocketsVideoTimer(time, timer, socketID);
  };

  const onReloadContent = () => {
    reloadContent();
    setIsPlaying(false);
    setActiveChapter(false);
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <div className="video-player | w-full">
      {!video?.hidePlay && (
        <header className="relative flex justify-center rounded-md p-6">
          <hr className="absolute inset-0 h-full w-full bg-light-primary rounded-md" />
          <button
            className="absolute top-2 right-2 w-10"
            onClick={onReloadContent}
          >
            <Reload />
          </button>
          <button onClick={onPlay} className="relative">
            <PlayPause isPlaying={isPlaying} />
          </button>
        </header>
      )}

      {chapters?.map(c => <p>{c.seconds}  -  {currentDuration}</p>)}

      {chapters && (
        <ul
          ref={(el) => {
            chaptersRef.current = el;
            updateChaptersHeight();
          }}
          className="video-player__chapters"
          style={{ height: `${chaptersHeight}px` }}
        >
          {chapters?.map(
            (chapter) =>
              chapter.seconds <= currentDuration &&
              chapter.end && (
                <li key={chapter.id} onClick={() => setActiveChapter(chapter)}>
                  <VideoChapter
                    onChapterUpdate={onChapterUpdate}
                    chapter={chapter}
                    isActiveChapter={activeChapter === chapter ? true : false}
                  />
                </li>
              )
          )}
        </ul>
      )}
    </div>
  );
};

export default VideoPlayer;
