/*//////////////////////*/
/* Imports
/*/ /////////////////////*/
import { useLayoutEffect, useRef, useEffect, useState } from "react";
import gsap from "gsap";
import { Draggable } from "gsap/Draggable";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import "./VideoSlider.styles.scss";
import {
  getHoursFromRangeAndPercent,
  getSecondsFromHours,
  getValueFromRangeAndPercent,
} from "../../../utils/helpers.utils";
gsap.registerPlugin(Draggable, DrawSVGPlugin);

const VideoSlider = ({
  video,
  updatePosition,
  updatePositionVertical,
  updateFinalPosition,
  angle = 0,
  updateIs3D,
  socketID
}) => {
  /*//////////////////////*/
  /* Variables
  /*/ /////////////////////*/
  const videoplayer = useRef();
  const [sliderType, setSliderType] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [range, setRange] = useState(null);
  const [current, setCurrent] = useState(null);

  /*//////////////////////*/
  /* Effects
  /*/ /////////////////////*/

  /* check texts */
  useEffect(() => {
    if (video.sliderStart && video.sliderEnd) {
      setStart(video.sliderStart);
      setCurrent(video.sliderStart);
      setEnd(video.sliderEnd);
      setSliderType(video.sliderHours ? "hours" : "timer");

      let diff = video.sliderHours
        ? Math.abs(
            getSecondsFromHours(video.sliderEnd) -
              getSecondsFromHours(video.sliderStart)
          )
        : Math.abs(video.sliderEnd - video.sliderStart);

      setRange(diff);
    }
  }, [video]);

  /* animations */
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.set(".videoplayer__path", {
        transformOrigin: "center center",
        drawSVG: `0% 0%`,
      });
      Draggable.create(".videoplayer__circle", {
        type: "x",
        inertia: true,
        bounds: videoplayer.current,
        onDrag: function () {
          let percent = Math.abs(Math.round((this.x / this.maxX) * 100));
          gsap.set(".videoplayer__path", {
            drawSVG: `0% ${percent}%`,
          });
          updateCurrentPosition(percent);

          /* mise à jours texte */
          if (sliderType && range && start) {
            if (sliderType === "hours") {
              setCurrent(getHoursFromRangeAndPercent(percent, start, range));
            } else if (sliderType === "timer") {
              setCurrent(getValueFromRangeAndPercent(percent, start, range));
            }
          }
        },
        onDragEnd: function () {
          let percent = Math.abs(Math.round((this.x / this.maxX) * 100));
          updateCurrentFinalPosition(percent);
        },
      });
    }, videoplayer);

    return () => ctx.revert();
    // eslint-disable-next-line
  }, [sliderType, range, start]);

  /*//////////////////////*/
  /* Functions
  /*/ /////////////////////*/
  /* percent update */
  const updateCurrentPosition = (newPercent) => {
    updatePosition(newPercent);
  };
  const updateCurrentFinalPosition = (newPercent) => {
    updateFinalPosition(newPercent);
  };

  /*//////////////////////*/
  /* Return
  /*/ /////////////////////*/
  return (
    <div className="videoSlider py-12">
      <div className="relative flex justify-center pb-10">
        {start && <span className="start text-center">{start}</span>}
        {current && <span className="current">{current}</span>}
        {end && <span className="end text-center">{end}</span>}
      </div>
      <svg
        ref={videoplayer}
        className="pb-4"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 474 79"
      >
        <path
          className="stroke-dashed stroke-primary"
          d={start && end ? "M40.5 39h390" : "M.2 39.5H474"}
        />
        <path
          className="videoplayer__path stroke-plain stroke-primary"
          d={start && end ? "M40.5 39h390" : "M.2 39.5H474"}
        />
        <circle
          className="videoplayer__circle fill-primary"
          cx="39.5"
          cy="39.5"
          r="39.5"
        />
        {end && (
          <line
            className="stroke-small stroke-primary"
            x1="434"
            y1="79"
            x2="434"
            y2="0"
          />
        )}
        {start && (
          <line
            className="stroke-small stroke-primary"
            x1="40"
            y1="79"
            x2="40"
            y2="0"
          />
        )}
      </svg>
    </div>
  );
};

export default VideoSlider;
